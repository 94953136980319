import SEO from '../components/core/meta/SEO';
import React from 'react';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import { WelfareAziendalePageQuery } from '../../generated/graphql-types';
import BackgroundImage from 'gatsby-background-image';
import SmallPartnerList from '../components/partials/PartnersList/SmallPartnerList';
import TwoPartLayout from '../components/partials/TwoPartLayout/TwoPartLayout';
import BrochureBanner from '../components/partials/BrochureBanner/BrochureBanner';
import ContactFormButton from '../components/partials/ContactFormButton/ContactFormButton';
import { convertToBgImage } from 'gbimage-bridge';

const content = [
  'Cosa succede quando un dipendente a casa ha uno o più carichi di cura? Che peso ha questa responsabilità a livello pratico e a livello psicologico? Come possiamo aiutarlo a gestire con equilibrio e in momenti separati la sfera professionale e quella personale? Qual è la nuova frontiera di un welfare aziendale fatto bene, che parta dalle reali necessità dei lavoratori? Noi qualche idea ce l’abbiamo!',
  'Offriamo alle aziende il servizio UGO e percorsi di analisi del bisogno e cultura aziendale che favoriscano l’equilibrio e il well being. In base alla composizione della popolazione aziendale e ai suoi bisogni, siamo in grado di costruire un programma che risolva alcune difficoltà presenti in azienda e favorisca un ambiente sereno e ingaggiato, dove il lavoratore può essere presente a lavoro con concentrazione, soddisfazione e produttività.',
];

const elderlyCareContent = [
  {
    boldText: 'Mia mamma ha un’altra visita medica.',
    text:
      'Organizzare trasporto e accompagnamento non è mai facile soprattutto se la persona ha necessità di un supporto. UGO risolve questo problema.',
  },
  {
    boldText: 'Devo fare delle commissioni per i miei.',
    text:
      'Ritirare un referto, fare la spesa, passare in banca, andare in farmacia, ritirare le cose in lavanderia. Piccole cose, certo, ma chi le fa? E quando se sono nell’orario di lavoro?',
  },
  {
    boldText: 'Ha bisogno di stimoli, deve uscire e mantenersi attivo.',
    text:
      'Ma come faccio? Assistere una persona significa capire i suoi bisogni pratici e non. Una chiacchierata, una passeggiata, una partita a carte sono un aiuto enorme ma come faccio se sono a lavoro? o se ho i bambini?',
  },
];

const WelfareAziendalePage = () => {
  const welfareAziendaleData = useStaticQuery<
    WelfareAziendalePageQuery
  >(graphql`
    query WelfareAziendalePage {
      headerBackground: file(relativePath: { eq: "b2b/headers/company.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      busyPersonImage: file(relativePath: { eq: "b2b/busy-person.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      happyPersonImage: file(relativePath: { eq: "b2b/happy-person.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const headerBackground = convertToBgImage(
    welfareAziendaleData.headerBackground.childImageSharp.gatsbyImageData
  );
  const busyPersonImage = convertToBgImage(
    welfareAziendaleData.busyPersonImage.childImageSharp.gatsbyImageData
  );

  return (
    <>
      <SEO url={'/welfare-aziendale'} title={'Welfare aziendale'} />
      <BackgroundImage {...headerBackground}>
        <div
          className="bg-black bg-opacity-60 py-16 flex"
          style={{ minHeight: '460px' }}
        >
          <Container className="flex flex-col justify-center">
            <Heading
              variant="h2"
              className="text-center text-white"
              style={{ color: '#ffffff' }}
            >
              {'Se il dipendente sta bene, l’azienda sta bene.'}
            </Heading>
            <div className="flex justify-center">
              <ContactFormButton />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <div className="pt-16">
        <Container className="mb-16">
          <div className="mb-8">
            <h3 className="text-h3-mobile md:text-h3 tracking-h3 font-bold mb-8">
              {
                'I dipendenti sono prima di tutto persone e in quanto tali hanno una sfera di bisogni e di responsabilità che va oltre il proprio calendar.'
              }
            </h3>
            {content.map((m, idx) => (
              <p
                key={idx}
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                {m}
              </p>
            ))}
          </div>
          <div className="flex justify-center">
            <ContactFormButton />
          </div>
        </Container>
        <Container className="mb-16">
          <SmallPartnerList
            partnerList={[
              'partner58',
              'partner57',
              'partner60',
              'partner59',
              'partner61',
            ]}
          />
        </Container>
        <div className="grid grid-cols-1 md:flex md:flex-wrap">
          <div className="w-full md:w-1/2 h-0 md:h-auto md:flex-grow">
            <BackgroundImage {...busyPersonImage} className="h-full" />
          </div>
          <div
            className="w-full pt-16 md:py-16 md:flex-grow md:w-1/2"
            style={{ backgroundColor: '#FEF7EB' }}
          >
            <Container className="max-w-xl! space-y-8">
              <h4 className="text-h4-mobile md:text-h4 tracking-h4 font-bold mb-8">
                {
                  'Cosa succede ai tuoi dipendenti quando i carichi di cura sono troppi?'
                }
              </h4>
              {elderlyCareContent.map((e, idx) => (
                <p
                  key={idx}
                  style={{
                    fontSize: '17px',
                    lineHeight: '32px',
                    marginBottom: '1rem',
                  }}
                >
                  <b>{e.boldText}</b>
                  {' ' + e.text}
                </p>
              ))}
            </Container>
          </div>
        </div>
        <TwoPartLayout
          layoutImage={
            welfareAziendaleData.happyPersonImage.childImageSharp
              .gatsbyImageData
          }
          halfLayout={true}
          removeBottomMargin={true}
        >
          <h4 className="text-h4-mobile md:text-h4 tracking-h4 font-bold mb-8">
            {'UGO ti facilita la vita!'}
          </h4>
          <p
            style={{
              fontSize: '17px',
              lineHeight: '32px',
              marginBottom: '1rem',
            }}
          >
            {
              'UGO non è ‘solo’ un servizio: è un supporto pratico, psicologico, e l’occasione per superare la sensazione di dover fare sempre e solo tutto tu. Per questo gli utenti se ne innamorano e non vogliono più farne a meno. Quanto vale la serenità e la qualità della tua vita e delle persone che ami?'
            }
          </p>
          <p
            style={{
              fontSize: '1.25rem',
              lineHeight: '32px',
              marginBottom: '1rem',
              fontWeight: 700,
            }}
          >
            {
              'Puoi supportare i tuoi dipendenti mettendo UGO a loro disposizione.'
            }
          </p>
        </TwoPartLayout>
        <BrochureBanner />
      </div>
    </>
  );
};

export default WelfareAziendalePage;
